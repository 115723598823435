<template>
  <sms-content v-if="item" :breadcrumbs="breadcrumbs">
    <sms-grid-row>
      <sms-card v-if="item" :body-padding="0">
        <div class="table-responsive">
          <table class="table card-table table-vcenter text-nowrap">
            <tbody>
              <tr v-for="(field,index) in module.fields" :key="index">
                <th scope="row" style="width: 10%;">{{ field.label }}</th>
                <td>
                  <component @onUpdateProperty="loadItem" :is="field.type + '-field'" :item="item" :field="field"></component>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </sms-card>
    </sms-grid-row>

    <template v-slot:tools>
      <a @click.prevent="deleteItem" href="#">
        <sms-icon name="fa fa-trash fa-2x" color="red"></sms-icon>
      </a>
    </template>
  </sms-content>
</template>

<script>
import SmsContent from "@/views/partials/Content";
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";
import axios from "@/plugins/axios";
import SmsIcon from "@/views/components/Icon";

import StringField from './fields/editable/string'
import PhoneField from './fields/editable/phone'
import DateField from './fields/editable/date'
import CalculatedField from './fields/editable/calculated'
import SelectField from './fields/editable/select'

export default {
  name: 'CrudIndex',
  components: {
    SmsIcon,
    SmsCard,
    SmsGridRow,
    SmsContent,
    StringField,
    PhoneField,
    DateField,
    CalculatedField,
    SelectField
  },
  data() {
    return {
      item: null
    }
  },
  computed: {
    module() {
      return this.$store.getters["crud/currentModule"]
    },
    breadcrumbs() {
      return [
        {
          label: this.module.label,
          route: {name: 'crud-list', params: {module: this.module.alias}},
        },
        {
          label: this.item.title,
        }
      ]
    },
  },
  mounted() {
    this.loadItem()
  },
  methods: {
    loadItem() {
      axios.get('/crud/' + this.module.alias + '/' + this.$route.params.id).then(response => {
        this.item = response.data.item
      })
    },
    deleteItem() {
      if (!confirm('Delete?')) {
        return
      }

      axios.delete('/crud/' + this.module.alias + '/' + this.item.id).then(response => {
        this.$router.push({name: 'crud-list', params: {module: this.module.alias}})
      })
    }
  },
}
</script>
