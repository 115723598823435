<template>
  <date-picker
    :value="date"
     @input="onChanged"
  />
</template>

<script>
import DatePicker from "@/components/DatePicker"

export default {
  props: {
    item: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  components: {
    DatePicker,
  },
  data() {
    return {}
  },
  computed: {
    date() {
      return this.item.params[this.field.name] ? this.item.params[this.field.name] : null
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    onChanged(date) {
      const value = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()

      this.$store.dispatch('crud/saveProperty', {
        module: this.$store.getters["crud/currentModule"],
        item: this.item,
        property: this.field.name,
        value: value,
      }).then(response => {
        this.$emit('onUpdateProperty')
      })
    }
  },
}
</script>
