<template>
  <content-editable-input
          :value="item.params[field.name]"
          @onBlur="saveProperty"
          @onEnter="saveProperty"
  />
</template>

<script>
import ContentEditableInput from "@/components/ContentEditableInput";

export default {
  props: {
    item: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  components: {
    ContentEditableInput,
  },
  methods: {
    saveProperty(value) {
      this.$store.dispatch('crud/saveProperty', {
        module: this.$store.getters["crud/currentModule"],
        item: this.item,
        property: this.field.name,
        value: value,
      }).then(response => {
        this.$emit('onUpdateProperty')
      })
    },
  },
}
</script>
