<template>
  <select v-model="selectValue" @change="saveProperty">
    <option value=""></option>
    <option v-for="(label,value) in field.options" :key="value" :value="value">
      {{ label }}
    </option>
  </select>
</template>

<script>

export default {
  props: {
    item: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  data() {
    return {
      selectValue: null
    }
  },
  mounted() {
    this.selectValue = this.item.params[this.field.name]
  },
  methods: {
    saveProperty() {
      this.$store.dispatch('crud/saveProperty', {
        module: this.$store.getters["crud/currentModule"],
        item: this.item,
        property: this.field.name,
        value: this.selectValue,
      }).then(response => {
        this.$emit('onUpdateProperty')
      })
    },
  },
}
</script>
